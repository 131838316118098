import React from 'react'
import NavBar from '../components/NavBar'
import Box from '@material-ui/core/Box'
import * as styles from '../components/Hero.module.css'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

const Success = () => {
  return (
    <>
      <NavBar/>
      <Box
        style={{ height: '100vh', clipPath: 'none' }}
        className={`${styles.background} ${styles.gradient}`}/>
      <Box
        style={{ height: '100vh', clipPath: 'none' }}
        className={`${styles.background} ${styles.image}`}/>
      <Container>
        <Box
          mt={4}
          style={{ textAlign: 'center', color: 'white' }}>
          <Typography variant='h3'>
            Thanks for signing up
          </Typography>
          <Box mt={4}>
            <Typography variant='h5'>
              You should receive a confirmation email momentarily
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography variant='h5'>
              You can also contact us directly at <a style={{ color: '#440381', textDecoration: 'none' }} href='mailto:hello@pixelsearch.io'>hello@pixelsearch.io</a>
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default Success